









import { Component, Prop, Vue } from "vue-property-decorator";
import ConfirmModal from "../../commons/ConfirmModal.vue";
import {
  MutationDeleteMaintenanceArgs,
  RegularMaintenance,
} from "../../../types/gqlGeneratedPrivate";
import { api } from "../../../api";
import { EventBus } from "../../../enums";
import { apiClient } from "../../../utils/apiClient";
import { TablesActions, TablesNamespaces } from "../../../store/modules/tables";
import { alerts } from "../../../utils";
import { Action } from "vuex-class";
import { TableRefreshPayload } from "../../../types";

@Component({
  components: { ConfirmModal },
})
export default class MaintenanceDeleteModal extends Vue {
  @Prop({ type: String, required: true })
  private maintenanceName!: string;

  EventBus = EventBus;
  isSending = false;

  @Action(TablesActions.Refresh, {
    namespace: TablesNamespaces.MaintenancesTable,
  })
  refreshTable!: (payload: TableRefreshPayload) => Promise<void>;

  async onConfirm(payload: RegularMaintenance) {
    this.isSending = true;
    const result = await apiClient.callGraphqlPrivate<
      null,
      MutationDeleteMaintenanceArgs
    >({
      ...api.maintenances.deleteMaintenance,
      variables: {
        id: payload.id,
      },
    });
    if (result.data) {
      alerts.addSuccessAlert("DELETE_MAINTENANCE", "Smazáno");
      await this.refreshTable({
        namespace: TablesNamespaces.MaintenancesTable,
      });
    }
    this.isSending = false;
  }
}
