














































import Vue from "vue";
import Component from "vue-class-component";
import TableHeader from "../../commons/tables/TableHeader.vue";
import ActionsWrapper from "../../commons/tables/ActionsWrapper.vue";
import RoundButton from "../../commons/inputsAndControls/RoundButton.vue";
import { TableOptions } from "../../commons/tables/types";
import {
  FilterOperatorEnum,
  Maintenance,
  MaintenancesFilterEnum,
  MaintenanceStateEnum,
} from "../../../types/gqlGeneratedPrivate";
import { TablesNamespaces } from "../../../store/modules/tables";
import { eventBus } from "../../../utils/eventBus";
import { EventBus, Routes } from "../../../enums";
import Table from "../../commons/tables/Table.vue";
import { toLocalDateTime } from "../../../filters/datetime";
import MaintenanceDeleteModal from "./MaintenanceDeleteModal.vue";
import MaintenanceCompletionModal from "./MaintenanceCompletionModal.vue";
import { textForMaintenanceType } from "./maintenanceTypes";

@Component({
  components: {
    MaintenanceDeleteModal,
    MaintenanceCompletionModal,
    Table,
    RoundButton,
    ActionsWrapper,
    TableHeader,
  },
  filters: {
    toLocalDateTime,
  },
})
export default class PlannedMaintenancesTable extends Vue {
  private maintenanceName = "";

  Routes = Routes;
  textForMaintenanceType = textForMaintenanceType;

  tableOptions: TableOptions<Maintenance, MaintenancesFilterEnum> = {
    defaultSortBy: ["device"],
    defaultFilter: [
      {
        filter: {
          column: MaintenancesFilterEnum.State,
          operator: FilterOperatorEnum.Equal,
          values: [MaintenanceStateEnum.Planned],
        },
      },
    ],
    headers: [
      {
        text: "Zařízení",
        sortable: true,
        align: "start",
        value: "device",
      },
      {
        text: "Datum",
        sortable: true,
        align: "start",
        value: "plannedDate",
      },
      {
        text: "Typ",
        sortable: true,
        align: "start",
        value: "type",
      },
      {
        text: "Zodp. osoba",
        sortable: true,
        align: "start",
        value: "responsiblePerson",
      },
      {
        text: "",
        sortable: true,
        align: "start",
        value: "actions",
      },
    ],
    namespace: TablesNamespaces.MaintenancesTable,
  };

  deleteItem(maintenance: Maintenance): void {
    this.maintenanceName = `${maintenance.type} - ${maintenance.plannedDate} - ${maintenance.device}`;

    eventBus.$emit(EventBus.MaintenanceDeleteModal, maintenance);
  }

  completeItem(maintenance: Maintenance): void {
    eventBus.$emit(EventBus.MaintenanceCompletionModal, maintenance);
  }
}
