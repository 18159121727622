var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TableHeader',{attrs:{"table-options":_vm.tableOptions,"title":"Plánovaný servis"}}),_c('Table',{attrs:{"table-options":_vm.tableOptions},scopedSlots:_vm._u([{key:"device",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
          name: _vm.Routes.MaintenancesPlannedForm,
          params: {
            id: item.id.toString(),
          },
        }}},[_c('span',[_vm._v(_vm._s(item.device.name))])])]}},{key:"plannedDate",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocalDateTime")(item.plannedDate))+" ")]}},{key:"type",fn:function(ref){
        var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.textForMaintenanceType(value))+" ")]}},{key:"responsiblePerson",fn:function(ref){
        var value = ref.value;
return [_vm._v(" "+_vm._s(value.firstname)+" "+_vm._s(value.surname)+" ")]}},{key:"actions",fn:function(ref){
        var item = ref.item;
return [_c('ActionsWrapper',[_c('RoundButton',{attrs:{"icon":"check-bold"},on:{"click":function () { return _vm.completeItem(item); }}}),_c('RoundButton',{attrs:{"to":{
            name: _vm.Routes.MaintenancesPlannedForm,
            params: {
              id: item.id.toString(),
            },
          },"icon":"pencil"}}),_c('RoundButton',{attrs:{"icon":"delete"},on:{"click":function () { return _vm.deleteItem(item); }}})],1)]}}])}),_c('MaintenanceDeleteModal',{attrs:{"service-name":_vm.maintenanceName}}),_c('MaintenanceCompletionModal')],1)}
var staticRenderFns = []

export { render, staticRenderFns }