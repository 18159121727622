

























































import { Component, Vue } from "vue-property-decorator";
import Modal from "../../commons/Modal.vue";
import { ValidationObserver } from "vee-validate";
import Button from "../../commons/inputsAndControls/Button.vue";
import TextField from "../../commons/inputsAndControls/TextField.vue";
import {
  CompleteMaintenanceMutation,
  CompleteMaintenanceMutationVariables,
  Maintenance,
  CompleteMaintenanceInput,
  MaintenanceTypeEnum,
} from "../../../types/gqlGeneratedPrivate";
import SelectBox from "../../commons/inputsAndControls/SelectBox.vue";
import Textarea from "../../commons/inputsAndControls/Textarea.vue";
import DateField from "../../commons/inputsAndControls/DateField.vue";
import { alerts, apiClient } from "../../../utils";
import { api } from "../../../api";
import { maintenanceTypes } from "./maintenanceTypes";
import { Action } from "vuex-class";
import { TablesActions, TablesNamespaces } from "../../../store/modules/tables";
import { TableRefreshPayload } from "../../../types";
import { EventBus } from "../../../enums";
import { eventBus } from "../../../utils/eventBus";

@Component({
  components: {
    DateField,
    Textarea,
    TextField,
    SelectBox,
    Modal,
    ValidationObserver,
    Button,
  },
})
export default class MaintenanceCompletionModal extends Vue {
  isOpen = false;
  isSending = false;
  maintenanceId = 0;

  @Action(TablesActions.Refresh, {
    namespace: TablesNamespaces.MaintenancesTable,
  })
  refreshTable!: (payload: TableRefreshPayload) => Promise<void>;

  formData: CompleteMaintenanceInput = {
    maintainedBy: "",
    maintenanceDate: "",
    price: 0,
    report: "",
    type: MaintenanceTypeEnum.Fault,
  };

  get maintenanceTypes() {
    return maintenanceTypes();
  }

  reset(): void {
    (this.$refs.observer as any).reset();
  }

  async created() {
    eventBus.$on(
      EventBus.MaintenanceCompletionModal,
      (maintenance: Maintenance) => {
        this.formData = {
          maintainedBy: "",
          maintenanceDate: new Date().toISOString(),
          price: 0,
          report: "",
          type: maintenance.type,
        };
        this.isOpen = true;
        this.maintenanceId = maintenance.id;
        this.initForm();
      }
    );
  }

  async onSubmit(): Promise<void> {
    const valid = await (this.$refs.observer as any).validate();
    if (valid && this.formData) {
      await this.sendForm(this.formData);
    }
  }

  async sendForm(maintenance: CompleteMaintenanceInput): Promise<void> {
    this.isSending = true;
    const result = await apiClient.callGraphqlPrivate<
      CompleteMaintenanceMutation,
      CompleteMaintenanceMutationVariables
    >({
      ...api.maintenances.completeMaintenance,
      variables: {
        input: maintenance,
        id: this.maintenanceId,
      },
    });
    if (result.data) {
      alerts.addSuccessAlert(
        "COMPLETE_MAINTENANCE",
        "Servis zařazen mezi uskutečněné"
      );
      await this.refreshTable({
        namespace: TablesNamespaces.MaintenancesTable,
      });
    }
    this.initForm();
    this.isOpen = false;
  }

  initForm(): void {
    this.isSending = false;
    this.$nextTick(() => {
      if (this.$refs.form) {
        (this.$refs.form as any).reset();
      }
    });
  }

  cancel(): void {
    this.isOpen = false;
    this.initForm();
  }
}
